import React from 'react';
import {
    BooleanField,
    DateField,
    FunctionField,
    Show,
    SimpleShowLayout,
    TabbedShowLayout,
    useRecordContext,
    WithRecord
} from 'react-admin';
import ReactPlayer from 'react-player';
import {Avatar, Box, Button, Card, Typography} from '@mui/material';
import {BigFalseIcon, BigTrueIcon} from "../icons/icons";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import ChildTranslationTable from "./ChildTranslationTable";
import {Link, useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";

const useStyles = makeStyles({
    label: {
        '& .RaLabeled-label span': {
            color: '#FFF',
        },
    },
});

const GetGuardians = () => {

    const record = useRecordContext();

    if (record && record.guardians && record.guardians.length > 0) {
        return record.guardians.map((guardian, i) => {
            return <div style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                padding: 16
            }} key={i}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight: 16}}>
                    <Avatar alt={`${guardian.given_name} ${guardian.family_name}`}
                            src={`${process.env.REACT_APP_CDN_URL}/${guardian.avatar}`}
                            sx={{width: 56, height: 56}} style={{marginRight: 16}}/>
                    <Link to={`/guardians/${guardian.id}/show`}>
                        <Typography component="h6"
                                    variant="h6">{guardian.given_name} {guardian.family_name}</Typography>
                    </Link>
                </div>
            </div>
        })
    }
}

const ChildShow = (props) => {

    const {t} = useTranslation();

    const classes = useStyles();

    // We'll want to access the theme
    const theme = useTheme();

    const navigate = useNavigate();

    return (
        <Show {...props}>
            <SimpleShowLayout sx={{backgroundColor: "#2f3c4c", color: "#FFF"}}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: 16,
                    marginTop: 16,
                    marginBottom: 16
                }}>
                    <FunctionField render={record =>
                        <Avatar sx={{height: 100, width: 100}}
                                src={`${process.env.REACT_APP_CDN_URL}/${record.profile_image_thumbnail}`}/>}
                    />
                    <div style={{marginLeft: 16, flexDirection: 'row'}}>
                        <WithRecord
                            render={record => <Typography component="h4"
                                                          variant="h4">{record.first_name} {record.last_name}</Typography>}/>
                        <DateField label={t('Birthdate')} source="birth_date" className={classes.label}
                                   sx={{fontSize: 'medium'}}/>
                        <FunctionField
                            render={(record) =>
                                (record.adoption_number && record.adoption_number !== '' && record.adoption_number !== null) ? (
                                    <div>({t('Adoption number')} : {record.adoption_number})</div>
                                ) : null
                            }
                        />
                    </div>
                </div>
            </SimpleShowLayout>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label={t('Details')}>
                    <div className="showie">
                        <Grid container spacing={4} sx={{padding: '8px'}} xs={12} lg={12}>
                            <Grid item xs={12} lg={12}>
                                <div className="custom-box">
                                    <Grid container spacing={3}>
                                        <Grid item xs={4} sm={4} md={4} lg={2}>
                                            <Typography variant="h6" fontWeight="medium"
                                                        gutterBottom>{t('Verified')}</Typography>
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <BooleanField source="verified" TrueIcon={BigTrueIcon}
                                                                  FalseIcon={BigFalseIcon}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={2}>
                                            <Typography variant="h6" fontWeight="medium"
                                                        gutterBottom>{t('Sponsored')}</Typography>
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <BooleanField source="child_is_sponsored" TrueIcon={BigTrueIcon}
                                                                  FalseIcon={BigFalseIcon}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                            <Typography variant="h6" fontWeight="medium"
                                                        gutterBottom>{t('Contact frequency')}</Typography>
                                            <FunctionField
                                                render={(record) => {
                                                    return (
                                                        <Typography>{t(record.contact_frequency)}</Typography>
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <div className="custom-box">
                                    <Typography variant="h6" fontWeight="medium"
                                                gutterBottom>{t('General info')}</Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={6} md={6} lg={6}>
                                            <WithRecord
                                                render={record => <>
                                                    <Box mt={2}>
                                                        <Typography variant="caption" display="block"
                                                                    gutterBottom>{t('Supported by charity')}</Typography>
                                                        <Link to={`/charities/${record.charity_id}/show`}>
                                                            {record.charity_name}
                                                        </Link>
                                                    </Box>
                                                </>}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6}>
                                            <FunctionField render={record => (
                                                <Box mt={2}>
                                                    <Typography variant="caption" display="block"
                                                                gutterBottom>{t('Online')}</Typography>
                                                    <a
                                                        href={`${process.env.REACT_APP_NOFAM_URL}/child/${record.first_name}/${btoa(record.id.toString())}/nl`}
                                                        target="_blank"
                                                    >
                                                        {t('View online child details page')}
                                                    </a>
                                                </Box>
                                            )} />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>

                            <WithRecord
                                render={record => <>
                                    {record.child_is_sponsored && (
                                        <Grid item xs={12} lg={9}>
                                            <div class="custom-box">
                                                <Typography variant="h6" gutterBottom>{t('Sponsor info')}</Typography>
                                                <Grid container spacing={2} lg={12}>
                                                    <Grid item lg={6}>
                                                        <Typography style={{
                                                            fontWeight: 500,
                                                            fontSize: 12
                                                        }}>{t('\'Guardian(s)')}</Typography>
                                                        <GetGuardians/>
                                                    </Grid>
                                                    <Grid item lg={6}>
                                                        <Typography style={{fontWeight: 500, fontSize: 12}}
                                                                    gutterBottom>{t('Posts')}</Typography>
                                                        <Link to={`/posts?filter={"child_id":"${record.id}"}`}>
                                                            <Button
                                                                variant="contained">{t(`View posts for`)} {record.name}</Button>
                                                        </Link><br /><br />
                                                        <Button
                                                            onClick={() => {
                                                                navigate('/posts/create', {state: {child: record}});
                                                            }}
                                                            sx={{
                                                                color: 'white',
                                                                backgroundColor: theme.palette.secondary.main,
                                                                '&:hover': {backgroundColor: 'darkgreen'}
                                                            }}
                                                        >
                                                            {t('Share new post')}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    )}
                                </>}
                            />
                            <Grid item xs={12} lg={12}>
                                <div className="custom-box">
                                    <Typography variant="h6" fontWeight="medium"
                                                gutterBottom>{t('Description')}</Typography>
                                    <div>
                                        <Typography variant="h6" gutterBottom>{t('Intro')}</Typography>
                                        <FunctionField
                                            render={(record) => {
                                                return (
                                                    <Typography>{record.intro}</Typography>
                                                );
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Typography variant="h6" gutterBottom>{t('My story')}</Typography>
                                        <FunctionField
                                            style={{fontSize: 16}}
                                            render={(record) => {
                                                return (
                                                    <Typography>{record.family}</Typography>
                                                );
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Typography variant="h6" gutterBottom>{t('Why do I need help')}</Typography>
                                        <FunctionField
                                            style={{fontSize: 16}}
                                            render={(record) => {
                                                return (
                                                    <Typography>{record.help}</Typography>
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label={t('Media')}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >
                        <Grid item xs={6} md={5}>
                            <Card
                                sx={{
                                    alignItems: 'center',
                                    verticalAlign: 'center',
                                    padding: 1,
                                }}
                            >
                                <Typography component="h6" variant="h6">{t('Profile image')}</Typography>
                                <FunctionField
                                    label="Child profile Image"
                                    render={(record) => {
                                        return (
                                            <img
                                                height="400"
                                                src={`${process.env.REACT_APP_CDN_URL}/${record.profile_image}.${record.image_ext}`}
                                                alt={record.name}
                                            />
                                        );
                                    }}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Card
                                sx={{
                                    alignItems: 'center',
                                    verticalAlign: 'center',
                                    padding: 1,
                                }}
                            >
                                <Typography component="h6" variant="h6">{t('Introduction video')}</Typography>

                                <FunctionField
                                    label="Introduction video"
                                    render={(record) => {
                                        return (
                                            <ReactPlayer
                                                url={`${process.env.REACT_APP_CDN_URL}/${record.profile_video}.${record.video_ext}`}
                                                light={`${process.env.REACT_APP_CDN_URL}/${record.profile_video_thumbnail}`}
                                                controls={true}
                                            />
                                        );
                                    }}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label={t('Translations')}>
                    <FunctionField
                        render={(record) => {
                            return (
                                <ChildTranslationTable translations={record.translations}/>
                            );
                        }}
                    />
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    );
}

export default ChildShow;
